import React from 'react';

function Modal({ id, title, children, classes }) {
    return (
        <div className="modal" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Label`} aria-hidden="true">
            <div className={`modal-dialog ${classes ? classes : ''}`} role="document">
                <div className="modal-content">
                    {
                        title &&
                        <div className="modal-header">
                            <h5 className="modal-title" id={`${id}Label`}>{title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    }
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;