import { useEffect, useState } from 'react'
import Navbar from './components/Navbar'
import CheckItemsPage from './pages/CheckItemsPage'
import HomePage from './pages/HomePage'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

export default function App() {

  const [logedIn, setLogedIn] = useState(Boolean)

  const LocalUser = localStorage.getItem('UserData')


  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          localStorage.getItem('UserData') ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }

  useEffect(() => {
    localStorage.getItem('UserData') && setLogedIn(true)

  }, [])

  useEffect(() => {
    (logedIn && window.location.pathname !== '/repuestos/') && (window.location.pathname = '/repuestos/')
  }, [logedIn])


  return (
    <>
      {
        logedIn ? '' :
          <>
            <a hidden href="/repuestos"></a>
          </>
      }
      <Router>
        <Navbar logedIn={logedIn} setLogedIn={setLogedIn} />
        <Switch>
          <PrivateRoute path="/repuestos">
            <CheckItemsPage />
          </PrivateRoute>
          <Route path='/' exact>
            <HomePage logedIn={logedIn} setLogedIn={setLogedIn} />
          </Route>
        </Switch>
      </Router>
    </>
  )
}
