import Login from './Login';
import { Link, useLocation } from 'react-router-dom';
import '../css/navbar.css';

export default function Navbar({ logedIn, setLogedIn }) {

    const location = useLocation();

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-navbar py-2 py-lg-0">
            <div className="container">
                <div className="row mx-0 w-100 justify-content-between aling-items-center">
                    <div>
                        <p role="button" className="navbar-brand mb-0 font-weight-bold">Consulta de Precio</p>
                    </div>
                    <div className="navbar-nav">
                        {
                            location.pathname.length > 1 ? '' :
                                logedIn &&
                                <>
                                    {
                                        <>
                                            <Link className={`nav-link ${location.pathname === "/repuestos" ? 'active' : ''}`} to="/repuestos">Repuestos</Link>
                                            {/* <Link className={`nav-link ${location.pathname === "/bike" ? 'active' : ''}`} to="/bike">Bike</Link>
                                            <Link className={`nav-link ${location.pathname === "/electro" ? 'active' : ''}`} to="/electro">Electro</Link> */}

                                        </>
                                    }
                                </>
                        }
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Login logedIn={logedIn} setLogedIn={setLogedIn} />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}