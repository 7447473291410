import Swal from 'sweetalert2'

function SweetToast({ countWords, searchType }) {
    return (
        Swal.fire({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
            text: `La busqueda por ${searchType} debe contener al menos ${countWords} caracteres`
        })
    )
}

export default SweetToast