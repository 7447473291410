import Swal from 'sweetalert2'

export default function SweetAlert({ icon, title, text, showConfirmButton, showCancelButton, html, funct }) {
    Swal.fire({
        icon: icon ? icon : '',
        html: html ? html : '',
        title: title ? title : '',
        text: text ? text : '',
        showConfirmButton: showConfirmButton ? showConfirmButton : '',
        showCancelButton: showCancelButton ? showCancelButton : '',
        showClass: {
            popup: '',
            icon: ''
        },
        hideClass: {
            popup: ''
        }
    }).then((result) => {
        if (funct) {
            if (result.isConfirmed) {
                funct()
            };
        }
    })
}

