import { useState, useRef, useEffect } from 'react'
import { Table, Input, Button, Space, ConfigProvider } from 'antd'
import Language from 'antd/lib/locale/es_ES'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'
import '../css/table.css'

export default function AntTable({ datos }) {

    const [DatatableData, setDatatableData] = useState({
        columns: [],
        data: []
    })
    // const [searchInput, setSearchInput] = useState();
    const tabla = useRef(null)
    let searchInput

    // const discPerc = localStorage.getItem('UserData') && JSON.parse(localStorage.getItem('UserData'))[0].U_Descuento

    const [filters, setFilters] = useState({
        searchText: '',
        searchedColumn: ''
    })

    const getColumnSearchProps = (dataIndex, label) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node
                    }}
                    placeholder={`Buscar ${label}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100)
            }
        },
        render: text =>
            filters.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[filters.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setFilters({
            ...filters,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        })
    }

    const handleReset = clearFilters => {
        clearFilters()
        setFilters({ ...filters, searchText: '' })
    }



    useEffect(() => {
        const tabla = document.querySelector('.ant-table-content table')
        if (datos && datos.length > 0) {
            setDatatableData({
                ...DatatableData, columns: (Object.keys(datos[0]).map(col => {
                    if (col !== 'id__' && col !== "WhsCode" && col !== "CardCode") {
                        return {
                            title: col === "ItemCode" && 'Codigo Yuhmak' || col === "ItemName" && 'Descripción' || col === "SuppCatNum" && 'Cod. Fabricante' || col === "OnHand" && 'Stock' || col === "Price" && 'Precio S/IVA' || col === "Rate" && 'Precio Final',
                            dataIndex: col,
                            key: col,
                            width: '30%',
                            ...getColumnSearchProps(col, col === "ItemCode" && 'Codigo Yuhmak' || col === "ItemName" && 'Descripción' || col === "SuppCatNum" && 'Cod. Fabricante' || col === "OnHand" && 'Stock' || col === "Price" && 'Precio S/IVA' || col === "Rate" && 'Precio Final')
                        }
                    }
                })).filter(x => x),
                data: datos.map(({ ItemCode, ItemName, SuppCatNum, Price, Rate, OnHand }, i) => {

                    return {
                        key: i,
                        ItemCode: ItemCode,
                        ItemName: ItemName && ItemName.substring(0, 35),
                        SuppCatNum: SuppCatNum !== null ? SuppCatNum : '-',
                        OnHand: OnHand,
                        // Price: '$' + (Price - (Price * (Number(discPerc) / 100))).toFixed(2),
                        Price: '$' + (Price).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
                        // Rate: Price && '$' + ((((Price - Price * Number(discPerc) / 100) * Rate) / 100) + (Price - Price * Number(discPerc) / 100)).toFixed(2),
                        Rate: '$' + (Price * (1 + Rate / 100)).toLocaleString('de-DE', { maximumFractionDigits: 2 })
                    }
                })
            })
            tabla.classList.add('table', 'table-responsive', 'overflow-auto', 'table-hover')
            tabla.style.height = '80vh'
        }
    }, [datos])

    return (
        <ConfigProvider locale={Language}>
            {datos && datos.length > 0 ?
                <Table
                    columns={DatatableData.columns}
                    dataSource={DatatableData.data}
                    ref={tabla}
                    scroll={{ x: 1500 }}
                />
                :
                null
            }
        </ConfigProvider>
    )
}