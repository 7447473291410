export default {
    isAuthenticated: () => {

        function getCookie(cname) {
            var name = cname + "="
            var decodedCookie = decodeURIComponent(document.cookie)
            var ca = decodedCookie.split(';')
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i]
                while (c.charAt(0) === ' ') {
                    c = c.substring(1)
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length)
                }
            }
            return ""
        }

        function checkCookie() {
            var logedIn = getCookie("B1SESSION")
            if (logedIn !== "") {
                return true
            } else {
                return false
            }
        }

        const isLogedIn = checkCookie()

        isLogedIn ? localStorage.setItem('logedIn', "true") : localStorage.setItem('logedIn', "false")


        return checkCookie()
    },
    logOut: () => {
        let cookies = document.cookie.split(";")
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i]
            let eqPos = cookie.indexOf("=")
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
        }
        localStorage.setItem('logedIn', 'false')
        localStorage.removeItem('UserData')
        window.location = '/'
    }
}