import { useState, useEffect } from "react"
import { useCookies } from 'react-cookie'
import '../index.css'
import AntTable from "../elements/AntTable"
import Table from "../elements/Table"
import clientAxios from '../utils/clientAxios'
import Notiflix from 'notiflix'
import SweetAlert from "../components/SweetAlert"
import auth from '../utils/auth'
import SweetToast from '../components/SweetToast'
import SweetToast2 from '../components/SweetToast2'

export default function CheckItemsPage() {

    const [valueSearch, setValueSearch] = useState()
    const [skip, setSkip] = useState(0)
    const [typeSearch, setTypeSearch] = useState('Buscar Por')
    const [items, setItems] = useState()
    const cookie = useCookies([0])

    const getItemPrice = async (e) => {
        if (e.cancelable) {
            e.preventDefault()
            setSkip(0)
        }
        if (valueSearch && !!valueSearch.code == false) {
            SweetAlert({ icon: 'error', text: 'Por favor, ingresar un valor a buscar', showConfirmButton: true })
            return
        }
        if (typeSearch === 'Buscar Por') {
            SweetAlert({ icon: 'error', text: 'Por favor, ingresar tipo de valor a buscar', showConfirmButton: true })
            return
        }
        if (typeSearch === 'Cod. Fabricante' && !!valueSearch.code && valueSearch.code.length < 3) {
            SweetToast({ countWords: 3, searchType: typeSearch })
            return
        }
        if (typeSearch === 'Cod. Yuhmak' && !!valueSearch.code && valueSearch.code.length < 3) {
            SweetToast({ countWords: 3, searchType: typeSearch })
            return
        }
        if (typeSearch === 'Descripción' && !!valueSearch.code && valueSearch.code.trim().length < 10) {
            SweetToast2({ countWords: 10, searchType: typeSearch })
            return
        }
        Notiflix.Loading.dots('Buscando Producto/s...')
        setItems(undefined)
        try {
            const resultGetItem = await clientAxios.get('/pricelist.php', {
                params: {
                    B1SESSION: cookie[0].B1SESSION,
                    ROUTEID: cookie[0].ROUTEID,
                    code: valueSearch && valueSearch.code.trim(),
                    typeSearch: valueSearch && valueSearch.typeSearch,
                    skip: !e.cancelable ? e : 0
                }
            })
            Notiflix.Loading.remove()
            if (resultGetItem.data.result.length === 0) {
                SweetAlert({ icon: 'info', title: 'Ups', text: 'No existe ningún articulo para el código ingresado', showConfirmButton: true })
            }
            setItems(resultGetItem.data.result)
        } catch (error) {
            Notiflix.Loading.remove()
            console.log(error)
            const { response } = error
            if (response.data.status === 301 && response.data.result.error_msg.includes('Invalid session.')) {
                SweetAlert({ icon: 'error', title: 'Tu sesión expiró', text: 'Por favor, vuelve a ingresar', showConfirmButton: true, funct: () => auth.logOut() })
            }
        }
    }

    useEffect(() => {
        Notiflix.Loading.init({
            messageColor: 'white',
            svgColor: '#354a5f',
            backgroundColor: 'rgba(0,0,0,0.3)',
        })
    }, [])

    return (
        <div className="container">
            <form className="row justify-content-end mx-0 my-4" onSubmit={getItemPrice}>
                <div className="input-group input-group-sm col-12 col-md-4">
                    <div className="input-group-prepend">
                        <button className="btn btn-outline-light py-1 dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{typeSearch}</button>
                        <div className="dropdown-menu">
                            <p className="dropdown-item mb-0" onClick={(e) => { setValueSearch({ ...valueSearch, typeSearch: 'maker' }); setTypeSearch(e.target.textContent) }}>Cod. Fabricante</p>
                            <p className="dropdown-item mb-0" onClick={(e) => { setValueSearch({ ...valueSearch, typeSearch: 'yuhmak' }); setTypeSearch(e.target.textContent) }}>Cod. Yuhmak</p>
                            {<p className="dropdown-item mb-0" onClick={(e) => { setValueSearch({ ...valueSearch, typeSearch: 'desc' }); setTypeSearch(e.target.textContent) }}>Descripción</p>}
                        </div>
                    </div>
                    <input type="text" name="code" className="form-control py-3" placeholder={typeSearch !== 'Buscar Por' ? ` Ingresar ${typeSearch.includes('Cod.') ? 'Código' : 'Descripción'}` : 'Elegir tipo de Búsqueda'} title="Ingrese valor para iniciar la busqueda" onChange={e => setValueSearch({ ...valueSearch, code: e.target.value })} />
                    <input type="submit" value="Buscar" className="btn btn-outline-light py-1" />
                </div>
            </form>
            <div className="my-4">
                <AntTable datos={items} />
                {/* <Table data={items} skip={skip} setSkip={setSkip} getItemPrice={getItemPrice} /> */}
            </div>
        </div>
    )
}