import { useEffect, useRef, useState } from 'react'
import { useCookies } from "react-cookie"
import clientAxios from '../utils/clientAxios'
import auth from '../utils/auth'
import '../css/navbar.css'
import Modal from '../elements/Modal'
import SweetAlert from './SweetAlert'


export default function Login({ logedIn, setLogedIn }) {

    const [cookies, setCookie, removeCookie] = useCookies()
    const [loginData, setLoginData] = useState()
    const [userData, setUserData] = useState()
    const [tryTologin, setTryTologin] = useState(false)

    const buttonLogin = useRef()
    const buttonCloseModalLogin = useRef()

    const loginHandler = async (e) => {
        e.preventDefault()
        setTryTologin(true)
        try {
            const resultLogin = await clientAxios.post(`/auth.php`, loginData)
            setCookie('B1SESSION', resultLogin.data.result.B1SESSION, { path: '/' })
            setCookie('ROUTEID', resultLogin.data.result.ROUTEID, { path: '/' })
            localStorage.setItem('UserData', JSON.stringify(resultLogin.data.status))
            setUserData(resultLogin.data.status)
            setTryTologin(false)
            buttonCloseModalLogin.current.click()
            auth.isAuthenticated()
            setLogedIn(true)
        } catch (error) {
            const { response } = error
            console.log(error)
            setTryTologin(false)
            SweetAlert({ icon: 'error', title: 'Ocurrió un error', text: (response.data.result.error_msg && response.data.result.error_msg.includes('Credentials are Incorrects')) ? 'Las credenciales son incorrectas' : !response.data.result.error_msg && 'Ocurrió un error' })
        }
    }

    const logout = async () => {
        try {
            await clientAxios.post('/auth.php', { tryLogout: 'tryLogout' })
            localStorage.setItem('logedIn', 'false')
            Object.keys(cookies).map(c => removeCookie(c, { path: '/' }))
            auth.logOut()
            setLogedIn(false)
        } catch (error) {
            console.log('Error Logout ->', error)
        }
    }

    useEffect(() => {
        localStorage.getItem('UserData') ? setLogedIn(true) : setLogedIn(false)
        setUserData(JSON.parse(localStorage.getItem('UserData')))
    }, [logedIn, setLogedIn])

    return (
        <>
            {
                logedIn ?
                    <button className="btn btn-link text-decoration-none px-2 text-white" onClick={logout}>Salir</button>
                    :
                    <button className="btn btn-link text-decoration-none px-2 text-white" data-toggle="modal" data-target="#loginModal">Ingresar</button>
            }
            {
                <Modal classes="modal-dialog-centered modal-sm" id="loginModal" >
                    <form>
                        <div className="form-group">
                            <label htmlFor="user">Usuario</label>
                            <input type="text" className="form-control" name="user" onChange={(e) => setLoginData({ ...loginData, [e.target.name]: e.target.value })} id="user" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Password">Contraseña</label>
                            <input type="password" className="form-control" name="userPass" onChange={(e) => setLoginData({ ...loginData, [e.target.name]: e.target.value })} id="Password" onKeyPress={(e) => e.key === 'Enter' && buttonLogin.current.click()} />
                        </div>
                    </form>
                    <div className="modal-footer">
                        <button onClick={loginHandler} ref={buttonLogin} className="btn btn-primary">{tryTologin ? <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : 'Ingresar'}</button>
                        <button type="button" ref={buttonCloseModalLogin} className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    </div>
                </Modal>
            }
        </>
    )
}